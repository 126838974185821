<template>
  
  <v-container>
    <div>
      <v-toolbar
        dark
        prominent
        src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
        color="teal-darken-1"
      >
  
        <v-toolbar-title>時間割ジェネレータ</v-toolbar-title>
  
      </v-toolbar>
    </div>
    <br>

    <v-container>
    <v-row class="text-center" align="center">
      <v-col 
        v-for="n in 1"
        :key="n"
        cols="12"
        sm="6"
      >
          <img src="https://d3mo8ojg82d7tl.cloudfront.net/top.png" alt="時間割ジェネレータ" class="image">
      </v-col>
      <v-col
        v-for="n in 1"
        :key="n"
        cols="12"
        sm="6"
      >
        <v-carousel
          cycle
          height="400"
          hide-delimiters
          :show-arrows="true"
        >
          <v-carousel-item
            v-for="(item,i) in items"
            :key="i"
            :src="item.src"
            reverse-transition="fade-transition"
            transition="fade-transition"
          ></v-carousel-item>
        </v-carousel>
        <p class="mt-5">時間割ジェネレータによって作成された時間割の出力データ(サンプル)です。</p>
        <v-btn 
          size="x-large" 
          href="https://d3mo8ojg82d7tl.cloudfront.net/student_schedule.xlsx" 
          color="teal-lighten-2"
          variant="elevated"
          class="mt-5 mr-5"
        >
          生徒用時間割サンプル(EXCEL)
        </v-btn>
        <v-btn 
          size="x-large" 
          href="https://d3mo8ojg82d7tl.cloudfront.net/teacher_schedule.xlsx" 
          color="teal-lighten-2"
          variant="elevated"
          class="mt-5 mr-5"
        >
          教員用時間割サンプル(EXCEL)
        </v-btn>
      </v-col>
    </v-row>
    </v-container>

    <v-container>
      <h2 class="text-center headline font-weight-bold mb-7 mt-10">時間割ジェネレータの特長</h2>
      <br>
      <v-row align="center" justify="center">
        <v-col v-for="(step, i) in steps" :key="i" cols="auto">
          <v-card class="mx-auto" max-width="500" max-height="700" variant="elevated">

            <v-card-item>
              <div>
                <div class="text-center text-overline mb-1">特長{{ i+1 }}</div>
                <div class="text-center text-h6 mb-1">{{ step.title }}</div>
                <v-img
                  cover
                  height="auto"
                  width="auto"
                  :src="step.img_url"
                ></v-img>

                <p class="mt-5 mb-3">{{ step.text }}</p>

              </div>
            </v-card-item>

          </v-card>
        </v-col>
      </v-row>
      <br>
      <v-col cols="auto" class="text-center">

        <v-btn 
          size="x-large" 
          href="https://benesoftware.base.shop/" 
          color="teal-lighten-1"
          variant="elevated"
          class="mt-10 mr-5"
        >
          トライアル版はこちら
        </v-btn>

        <v-btn 
          size="x-large" 
          href="https://d3mo8ojg82d7tl.cloudfront.net/jikanwari-generator-manual.pdf" 
          color="purple-lighten-1"
          variant="elevated"
          class="mt-10 mr-5"
        >
          マニュアル(PDF)
        </v-btn>
      </v-col>
      <br>
    </v-container>
    <v-footer class="d-flex align-center justify-center">
      &copy; 2024 bene software (ベネソフトウエア)
    </v-footer>
  </v-container>
  <br>
</template>

<script>

export default {
  name: 'HelloWorld',

  data () {
    return {
      items: [
        {
          src: 'https://d3mo8ojg82d7tl.cloudfront.net/sche1.jpeg',
        },
        {
          src: 'https://d3mo8ojg82d7tl.cloudfront.net/sche2.jpeg',
        },

      ],
      steps: [
        {
          title: '大変な手作業なし！',
          text: '　従来だと数日かかっていた時間割編成作業が、ワンクリックかつたったの数分で完了します。業務効率が大幅にアップします。',
          img_url: 'https://d3mo8ojg82d7tl.cloudfront.net/1-1.svg',
        },
        { 
          title: '生徒・教員の時間割を自動作成', 
          text: '　生徒・教員の1週間の時間割を最適な組み合わせでソフトが自動作成してくれます。入力データを準備するだけでOK。',
          img_url: 'https://d3mo8ojg82d7tl.cloudfront.net/2-3.svg',
        },
        { 
          title: '安心のExcelフォーマット', 
          text: '　入力データの準備にはExcelを使用するので、新たなソフトウェアの操作を覚える必要がありません。出力データもExcelフォーマットなので、取り扱いが簡単です。',
          img_url: 'https://d3mo8ojg82d7tl.cloudfront.net/3-1.svg',
        }

      ]
    }
  },
}
</script>

<style scoped>
.image {
  max-width: 100%;
  height: auto;
}
</style>