import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import VueGtag from "vue-gtag"

loadFonts()

const app = createApp(App);

app.use(VueGtag, {
  config: { id: "G-1HJQBE8RX9" }
})

app.use(vuetify).mount('#app')
